import {
	useExternalCRMOverride,
	clientTypeOverride,
	wtcsOverride,
	useWasteSuctionSystemOverride,
} from "Configs/config"
import { useGlobalAlert } from "States/globalAlert"
import { trpc } from "Utils/trpc"
import ClientType from "admin-client-server/src/utils/clientType"
import { useEffect, useMemo } from "react"

export type OptionalClientType = keyof typeof ClientType | undefined

export const useConfig = () => {
	const {
		data: configData,
		refetch,
		isLoading: isConfigLoading,
	} = trpc.config.getClientConfig.useQuery(undefined, {
		retryOnMount: false,
	})
	const { setGlobalAlert } = useGlobalAlert()

	const isMWM = (clientTypeOverride ?? configData?.type) === "MWM"
	const isRealEstate = (clientTypeOverride ?? configData?.type) === "REAL_ESTATE"
	const useExternalCRM = useExternalCRMOverride ?? configData?.useExternalCRM

	const {
		mutate: updateConfig,
		mutateAsync: updateConfigAsync,
		isLoading: isUpdatingConfig,
	} = trpc.config.updateClientConfig.useMutation({
		onSuccess: () => {
			refetch()
			setGlobalAlert({
				type: "success",
				message: "systemMessages:changesSaved",
			})
		},
		onError: error => {
			setGlobalAlert({
				type: "error",
				message: "errors:failedSave",
				instructions: error.message,
			})
		},
	})

	const config = useMemo(
		() => ({
			...configData,
			useExternalCRM,
			type: (clientTypeOverride ?? configData?.type) as OptionalClientType,
			wasteTypeClassificationSystemId: wtcsOverride ?? configData?.wasteTypeClassificationSystemId,
			useRedundancyPoints: isMWM && useExternalCRM,
			useWasteSuctionSystem: useWasteSuctionSystemOverride ?? configData?.useWasteSuctionSystem,
		}),
		[configData, isMWM, useExternalCRM]
	)

	// If there are any overrides present that don't match config, update the config and reload the page
	useEffect(() => {
		const shouldUpdateType = clientTypeOverride && clientTypeOverride !== configData?.type
		const shouldUpdateUseExternalCRM =
			useExternalCRMOverride && useExternalCRMOverride !== configData?.useExternalCRM
		const shouldUpdateWtcs =
			wtcsOverride && wtcsOverride !== configData?.wasteTypeClassificationSystemId
		const shouldUpdateAny = shouldUpdateType || shouldUpdateUseExternalCRM || shouldUpdateWtcs
		if (shouldUpdateAny && configData) {
			updateConfigAsync({
				type: (clientTypeOverride as keyof typeof ClientType) ?? configData.type,
				useExternalCRM: useExternalCRMOverride ?? configData.useExternalCRM,
				wasteTypeClassificationSystemId:
					wtcsOverride ?? configData.wasteTypeClassificationSystemId ?? "",
				useWasteSuctionSystem: useWasteSuctionSystemOverride ?? configData.useWasteSuctionSystem,
			}).then(() => {
				window.location.reload()
			})
		}
	}, [configData, updateConfigAsync])

	return {
		config,
		refetch,
		updateConfig,
		isMWM,
		isRealEstate,
		isConfigLoading,
		isUpdatingConfig,
	}
}
